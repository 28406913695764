<template>
  <div :class="$bem()">
    <div :class="$bem('filter')">
      <filters />
    </div>

    <div v-if="mobileOnly" class="flex-fill">
      <mobile-header :objectsCount="objects.length" :totalCount="count" />

      <template v-if="isLoaded">
        <div :class="$bem('cards-wrapper')">
          <div v-if="objects && objects.length" :class="$bem('cards-mobile')">
            <ul :class="$bem('cards-mobile-list')">
              <li v-for="apt in objects" :key="apt.id">
                <catalog-card :apartment="apt" />
              </li>
            </ul>

            <infinite-loading @infinite="infiniteHandler">
              <div slot="spinner" />
            </infinite-loading>
          </div>

          <div v-else :class="$bem('no-results')">
            <img
              src="@/assets/images/no-results-01.webp"
              alt="EasyBase | No search results"
              width="320"
              height="320"
            />
          </div>
        </div>
      </template>
    </div>

    <div v-if="desktopOnly" :class="$bem('cards-wrapper')">
      <template v-if="isLoaded">
        <div
          v-if="objects && objects.length"
          ref="cardsDesktop"
          :class="$bem('cards-desktop')"
        >
          <div v-for="apt in objects" :key="apt.id">
            <catalog-card :apartment="apt" />
          </div>
        </div>

        <div v-else :class="$bem('no-results')">
          <img
            src="@/assets/images/no-results-01.webp"
            alt="EasyBase | No search results"
            width="320"
            height="320"
          />
        </div>

        <counter :objects="objects.length" :count="count" />
      </template>
    </div>
  </div>
</template>

<script>
import component from '@/mixins/component';
import mediaQueries from '@/mixins/mediaQueries';
import { mapState, mapActions, mapMutations } from 'vuex';
import { NOTIFICATIONS } from '@/helpers/constants';

export default {
  name: 'catalog',
  components: {
    Filters: () => import('@/components/app/Filters'),
    MobileHeader: () => import('@/components/app/mobile-header'),
    CatalogCard: () => import('@/components/app/CatalogCard'),
    Counter: () => import('..//counter/counter.vue'),
    InfiniteLoading: () => import('vue-infinite-loading')
  },
  props: {
    isArchive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    ...mapState({
      objects: (state) => state.main.objects,
      count: (state) => state.main.count,
      page: (state) => state.main.page,
      loaderObject: (state) => state.main.loaderObject
    })
  },
  methods: {
    ...mapActions(['getObjects']),
    ...mapMutations(['SET_PAGE']),
    scrollToEnd() {
      if (!this.page) {
        this.$notify({
          group: 'app',
          type: 'success',
          text: NOTIFICATIONS.viewedAllObjects,
          ignoreDuplicates: true
        });
        return;
      }
      this.getObjects();
    },
    async infiniteHandler($state) {
      if (this.$store.state.main.page) {
        if (this.$store.state.main.page === 1) {
          await this.getObjects({ isSearchById: false, getLastFilter: true });
        } else {
          await this.getObjects();
        }
        $state.loaded();
      }
    },
    setupHorizontalScroll(event) {
      if (this.$refs.cardsDesktop) {
        if (!event.target.closest('.product-modal, .partner-review-wrapper')) {
          // Добавляем горизонтальное движение
          this.$refs.cardsDesktop.scrollLeft += event.deltaX / 2;

          // Обработка вертикального движения как раньше
          this.$refs.cardsDesktop.scrollLeft += event.deltaY / 2;
          event.preventDefault();
        }
      }
    },
    checkScrollPosition(event) {
      let target = event.target;
      // Проверка, что блок прокручен до конца
      if (
        target.scrollLeft + target.offsetWidth + 300 >= target.scrollWidth &&
        !this.loaderObject
      ) {
        this.scrollToEnd();
      }
    }
  },
  async mounted() {
    this.SET_PAGE(1);
    await this.getObjects({ isSearchById: false, getLastFilter: true });
    this.isLoaded = true;

    this.$nextTick(() => {
      if (this.$refs.cardsDesktop) {
        this.$refs.cardsDesktop.addEventListener(
          'wheel',
          this.setupHorizontalScroll
        );
        this.$refs.cardsDesktop.addEventListener(
          'scroll',
          this.checkScrollPosition
        );
      }
    });
  },
  beforeDestroy() {
    if (this.$refs.cardsDesktop) {
      this.$refs.cardsDesktop.removeEventListener(
        'wheel',
        this.setupHorizontalScroll
      );
      this.$refs.cardsDesktop.removeEventListener(
        'scroll',
        this.checkScrollPosition
      );
    }
  },
  mixins: [component, mediaQueries]
};
</script>

<style lang="sass">
.catalog
  &__filter
    width: 267px
    flex-shrink: 0
    position: relative
    box-shadow: 0 0 15px 5px rgb(21, 51, 80)
    padding-top: 10px

    @include media-breakpoint-down(md)
      display: none

  &__cards-wrapper
    width: 100%
    height: 100%
    display: flex
    flex-direction: column

    @include media-breakpoint-up(lg)
      height: auto

  &__no-results
    flex: 0 0 100%
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    padding: 2rem

    img
      max-width: 100%
      max-height: 100%
      object-fit: contain

      @include media-breakpoint-down(md)
        width: 15rem
        height: 15rem

  &__cards-mobile
    display: flex
    margin: 0
    margin-top: 50px
    flex-direction: column

  &__cards-mobile-list
    list-style: none
    padding-left: 0

  &__cards-desktop
    display: flex
    margin: 15px 10px
    flex-direction: row
    overflow: auto
    scrollbar-width: thin
    scrollbar-color: darkgrey #f4f4f4
    width: calc(100vw - 285px)
    &::-webkit-scrollbar
      height: 14px
      padding-left: 20px
      position: absolute

    &::-webkit-scrollbar-thumb
      background-color: rgba(78, 111, 144, 0.5)
      border-radius: 10px

    &::-webkit-scrollbar-track
      background-color: rgba(56,84,106,.2)
      border-radius: 10px
</style>
